<template>
    <div>
        <span @click="reduce" class="reduce">-</span>
        <span class="count">{{count}}</span>
        <span @click="increase" class="increase">+</span>
    </div>
</template>

<script>
export default {
    name: "spin-control",
    props: ['max','item'],
    data() {
        return {
            count : 0
        }
    },

    methods : {
        increase (){
            if( this.count < this.max && this.item.count < this.max ){
                this.count++
                this.item.count++
                // this.$emit("increase")
            }
        },

        reduce () {
            if (this.count  > 0) {
                this.count--
                this.item.count--
                // this.$emit("reduce")
            }
        }
    }
}
</script>

<style>
    .reduce {
        border: 1px solid rgb(240, 106, 37);
        padding: 5px;
        border-radius: 10px;
        margin-right: 10px;
        cursor: pointer;
    }

    .increase {
        border: 1px solid rgb(240, 106, 37);
        padding: 5px;
        border-radius: 10px;
        margin-left: 10px;
        cursor: pointer;
    }

    .count {
        color: rgb(240, 106, 37);
    }
</style>