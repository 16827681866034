var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-title',{staticClass:"grid-close"},[_c('span',{staticClass:"headline"},[_vm._v("Opciones para "+_vm._s(_vm.addon.name)+" ")]),_c('v-btn',{staticStyle:{"right":"10px","top":"10px"},attrs:{"icon":"","color":"primary","fab":"","small":"","absolute":""},on:{"click":function($event){return _vm.$emit('cancel')}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("fa-times")])],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"px-4"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[(_vm.isMobile)?_c('a-transfer',{attrs:{"listStyle":{
                height: '300px!important',
                width: '100%',
              },"operationStyle":{
                width: '100%',
                textAlign: '-webkit-center',
                transform: 'rotate(90deg)',
              },"data-source":_vm.allProductsAddons,"titles":['Todas las opciones', 'Opciones vinculadas'],"target-keys":_vm.optionsSelected,"selected-keys":_vm.selectedKeys,"show-search":true,"render":(item) => item.title,"filter-option":_vm.filterOption,"locale":{
            itemUnit: '',
            itemsUnit: '',
            notFoundContent: 'Lista vacía',
            searchPlaceholder: 'Buscar sección',
          }},on:{"search":_vm.handleSearch,"change":_vm.handleChange,"selectChange":_vm.handleSelectChange}}):_c('a-transfer',{attrs:{"listStyle":{
            height: '400px!important',
            width: '45%',
            background: 'white',
          },"data-source":_vm.allProductsAddons,"titles":['Todas las opciones', 'Opciones vinculadas'],"target-keys":_vm.optionsSelected,"selected-keys":_vm.selectedKeys,"show-search":true,"render":(item) => item.title,"filter-option":_vm.filterOption,"locale":{
            itemUnit: '',
            itemsUnit: '',
            notFoundContent: 'Lista vacía',
            searchPlaceholder: 'Buscar sección',
          }},on:{"search":_vm.handleSearch,"change":_vm.handleChange,"selectChange":_vm.handleSelectChange}})],1)],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"flex-end"},[_c('v-btn',{staticClass:"save-btn px-4",attrs:{"color":"primary","dark":""},on:{"click":_vm.updateOptions}},[_vm._v("Aceptar")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }