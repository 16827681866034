<template>
  <div>
    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>
    <!-- <v-overlay class="loading-center" :value="save">
        <lottie
          :options="defaultOptions"
     :height="150"
          :width="150"
          v-on:animCreated="handleAnimation"
        />
      </v-overlay> -->

    <v-card class="d-flex flex-column">
      <v-card-title class="grid-close">
        <span class="headline">Clonar producto</span>
        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          :loading="loading"
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <h2>
                ¿Está seguro que desea clonar el producto
                <b>{{ product.name }}</b> ?
              </h2>
            </v-col>
          </v-row>
          <v-row class="mt-10">
            <v-col cols="6">
              <v-btn
                :loading="loading"
                @click="$emit('cancel')"
                block
                height="45"
                color="gray"
              >
                Cancelar
              </v-btn>
            </v-col>

            <v-col cols="6">
              <v-btn
                :loading="loading"
                @click="clone"
                block
                height="45"
                color="primary"
              >
                Clonar
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>
  
  <script>
import { db, fb } from "@/firebase";
// import Lottie from "@/components/Lottie";
// import lottieSettings from "@/mixins/lottieMixin";
// import moment from "moment-timezone";
import { mapState } from "vuex";
// import { Button } from "ant-design-vue";
// import { TimePicker } from "ant-design-vue";
// import ledgerMixin from "@/mixins/ledgerMixin";
// import schedule from "@/components/own-schedule";

export default {
  name: "section-edit",
  props: ["section", "product"],
  //   mixins: [ledgerMixin],
  components: {
    // lottie: Lottie,
    // "a-time-picker": TimePicker,
    // "a-button": Button,
    // // schedule,
  },
  data() {
    return {
      snackbar: false,
      loading: false,
      snackbarText: "",
    };
  },

  computed: {
    ...mapState(["selectedBusiness", "user"]),
  },
  methods: {
    async clone() {
      let productRef = db.collection("products").doc();
      let batchData = [];
      let addonsIds = [];

      this.loading = true;

      for (let i = 0; i < this.product.addons.length; i = i + 10) {
        const addons = this.product.addons.slice(i, i + 10);

        let addonsList = await db
          .collection("addons")
          .where(fb.firestore.FieldPath.documentId(), "in", addons)
          .where("deleted", "==", false)
          .get();

        for (let l = 0; l < addonsList.size; l++) {
          const addon = addonsList.docs[l].data();
          addon.parentProductId = productRef.id;
          let reference = db.collection("addons").doc();
          addonsIds.push(reference.id);
          batchData.push({ reference, data: addon });

          let productsByAddons = await db
            .collection("addons")
            .doc(addonsList.docs[l].id)
            .collection("products")
            .get();

          for (let m = 0; m < productsByAddons.size; m++) {
            const prd = productsByAddons.docs[m].data();
            prd.parentProductId = productRef.id;

            let mainProduct = await db
              .collection("products")
              .doc(prd.productId)
              .get();

            mainProduct = mainProduct.data();

            let mainPrdRef = db.collection("products").doc();

            batchData.push({ reference: mainPrdRef, data: mainProduct });

            prd.productId = mainPrdRef.id;

            let prdRef = await db
              .collection("addons")
              .doc(reference.id)
              .collection("products")
              .doc();

            batchData.push({ reference: prdRef, data: prd });
          }
        }
      }

      let pr = Object.assign({}, this.product);
      pr.name = `${pr.name}_copy`;
      pr.addons = addonsIds;
      pr.createdAt = new Date();
      batchData.push({ reference: productRef, data: pr });

      let batch = db.batch();

      for (let i = 0; i < batchData.length; i++) {
        const item = batchData[i];
        batch.set(item.reference, item.data);
      }

      await batch
        .commit()
        .then(() => {
          this.$emit("success")
        })
        .catch((error) => {
          this.snackbar = true;
          this.snackbarText = `Ocurrio un error: ${error}`;
        });
    },
  },
  mounted() {},
};
</script>
  
  <style scoped>

</style>
  