<template>
  <div>
    <v-snackbar top :timeout="3000" v-model="snackbar">
      Información actualizada correctamente.
    </v-snackbar>

    <!-- <v-overlay class="loading-center" :value="save">
        <lottie
            :options="defaultOptions"
            :height="300"
            :width="400"
            v-on:animCreated="handleAnimation"
        />
    </v-overlay> -->

    <skeleton v-if="save" />

    <v-row>
      <v-col cols="12" md="8" sm="8" lg="8">
        <v-card
          class="text-left pa-1 ma-1 flex-grow-1"
          :color="
            $vuetify.theme.dark ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.1)'
          "
          elevation="0"
          style="border-radius: 17px !important; overflow: hidden"
        >
          <v-card-text class="text--primary">
            <p class="title ma-0" style="color: #f06a25">Complementos</p>
            <v-divider></v-divider>

            <v-expansion-panels v-if="addons.length > 0" class="mt-5">
              <v-expansion-panel v-for="(item, i) of addons" :key="i">
                <v-expansion-panel-header color="white">
                  <v-container class="no-margin" fluid>
                    <v-row>
                      <v-col cols="6">
                        <v-chip style="font-weight: bold">
                          {{ item.name }}
                          {{ item.max != 0 ? `(elige ${item.max})` : "" }}
                        </v-chip>
                      </v-col>
                      <v-col cols="6">
                        <v-chip
                          style="font-weight: bold"
                          color="grey lighten-1 white--text"
                          v-if="item.required"
                        >
                          Obligatorio
                        </v-chip>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-expansion-panel-header>

                <v-expansion-panel-content color="white">
                  <v-divider class="m-0 p-0"></v-divider>
                  <label class="ml-10" v-if="item.children.length == 0">
                    Sin opciones
                  </label>

                  <div class="no-margin" v-if="item.controlType == 'checkbox'">
                    <v-container fluid class="ml-6 no-margin">
                      <v-row
                        class="no-margin"
                        v-for="(option, index) of item.children"
                        :key="index"
                      >
                        <v-col class="no-margin" cols="6">
                          <p class="mt-5 ml-5">
                            {{ getProductName(option.productId) }}
                          </p>
                        </v-col>
                        <v-col class="no-margin" cols="6">
                          <v-checkbox
                            :label="`${
                              option.price
                                ? formatPrice(option.price)
                                : formatPrice(0)
                            }`"
                          >
                          </v-checkbox>
                        </v-col>
                      </v-row>
                    </v-container>
                  </div>

                  <v-radio-group v-if="item.controlType == 'radio'" column>
                    <v-container class="ml-6 no-margin">
                      <v-row
                        class="no-margin mt-3"
                        v-for="(option, index) of item.children"
                        :key="index"
                      >
                        <v-col class="no-margin" cols="6">
                          <p class="ml-5">
                            {{ getProductName(option.productId) }}
                          </p>
                        </v-col>
                        <v-col class="no-margin" cols="6">
                          <v-radio
                            :label="`${formatPrice(option.price)}`"
                            color="primary"
                            :value="index"
                          >
                          </v-radio>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-radio-group>

                  <v-radio-group v-if="item.controlType == 'spin'" column>
                    <v-container class="ml-6 no-margin">
                      <v-row
                        class="no-margin mb-5"
                        v-for="(option, index) of item.children"
                        :key="index"
                      >
                        <v-col class="no-margin" cols="6">
                          <p class="ml-5">
                            {{ getProductName(option.productId) }}
                          </p>
                        </v-col>
                        <v-col class="no-margin" cols="6">
                          <spin-control :item="item" :max="item.max" />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-radio-group>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <!-- <a-collapse accordion v-if="addons.length > 0" @change="changeActivekey">
                        <a-collapse-panel :forceRender="true"  v-for="(item, index) of addons" :key="index" :header="item.name">
                            <div>
                                <div v-for="(option, index) of addonOptions" :key="index">
                                    {{option}}
                                </div>
                            </div>
                        </a-collapse-panel>
                    </a-collapse> -->
            <p
              class="d-block font-weight-bold mt-5"
              v-if="
                !product.addons ||
                addons.length == 0 ||
                (product.addons.length && product.addons.length == 0)
              "
            >
              Complementos no configurados
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- <v-col cols="12" md="4" sm="4" lg="4" >
            <v-card
                class="text-left pa-1 ma-1 flex-grow-1"
                :color="$vuetify.theme.dark?'rgba(0, 0, 0, 0.4)':'rgba(0, 0, 0, 0.1)'"
                elevation="0"
                style="border-radius: 17px !important; overflow:hidden;">
                <v-card-text class="text--primary">
                    <p class="title ma-0" style="color:#f06a25;">Imágenes</p>
                    <v-divider></v-divider>
                    <p class="d-block font-weight-bold mt-5" v-if="product.images.length == 0">
                        Sin imágenes
                    </p>

                    <v-container v-if="product.images.length >0" fluid>
                        <v-row>
                            <v-col v-for="image in product.images" :key="image" 
                                cols="6">
                                <v-card class="d-flex">
                                    <v-img :src="image" class="grey lighten-2">
                                        <template v-slot:placeholder>
                                            <v-row class="fill-height ma-0"
                                                align="center"
                                                justify="center">
                                                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                            </v-row>
                                        </template>
                                    </v-img>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-col> -->
    </v-row>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
// import Lottie from "../../../components/Lottie" // Never used JoseV.
// import * as animationData from "../../../assets/ochoColor.json"; // Never used JoseV.
// import { Parser } from "json2csv"; // Never used JoseV.
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import { mapActions, mapState } from "vuex";
// import { Collapse } from 'ant-design-vue'
import spinControl from "./spin-control";
// const collapsePanel = Collapse.Panel
import skeleton from "../skeleton/product-details-skeleton";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "HNL",
  minimumFractionDigits: 2,
});

export default {
  name: "section-details",
  props: ["product", "products"],
  components: {
    // lottie: Lottie, // Never used JoseV.
    skeleton,
    // 'a-collapse' : Collapse,
    // 'a-collapse-panel': collapsePanel,
    "spin-control": spinControl,
  },
  data() {
    return {
      addons: [],
      productsAddons: [],
      animationSpeed: 1.1,
      // Never used JoseV.
      // defaultOptions: {
      //     animationData: animationData.default,
      //     loop: true,
      //     autoplay: true
      // },
      allProducts: [],
      // editedItem : {}, // Never used JoseV.
      save: false,
      snackbar: false,
      tree: [],
      addonSelected: null,
    };
  },
  computed: {
    ...mapState(["selectedBusiness"]),
    filterDeletedProduct: function () {
      this.addons.forEach((item) => {
        let tempProductsArray = [];
        item.children.forEach((prod) => {
          if (!this.allProducts.includes(prod.productId)) {
            tempProductsArray.push(prod);
          }
        });
        item.children = tempProductsArray;
      });
    },
    // Never called JoseV.
    // productsInAddon: function() {
    //     if(this.addonSelected) {}
    // }
  },
  mounted() {
    this.getAllProducts();
    let self = this;

    if (this.product.addons.length > 0) {
      this.save = true;

      db.collection(`addons`)
        .where(fb.firestore.FieldPath.documentId(), "in", this.product.addons)
        .where("deleted", "==", false)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let addon = doc.data();
            addon.id = doc.id;
            addon.count = 0;

            let arrayProducts = [];

            db.collection(`addons/${doc.id}/products`)
              .where("deleted", "==", false)
              .get()
              .then((products) => {
                products.forEach((product) => {
                  let item = product.data();
                  item["id"] = product.id;
                  arrayProducts.push(item);
                });

                addon.children = arrayProducts;
                this.addons.push(addon);
                this.save = false;
                this.filterDeletedProduct;
              });
          });
        })
        .catch((err) => {
          console.log(err);
        });

      db.collection(`products`)
        .where("business", "array-contains", this.selectedBusiness[".key"])
        .where("addon", "==", true)
        .get()
        .then((querySnapshot) => {
          self.productsAddons = [];

          querySnapshot.forEach(function (doc) {
            let addon = doc.data();
            addon.id = doc.id;
            self.productsAddons.push(addon);
          });
        })
        .catch((err) => console.log(err));
      // this.save = false;
    }
  },
  methods: {
    // Never called JoseV.
    // handleAnimation: function(anim) {
    //     this.anim = anim;
    //     anim.setSpeed(this.animationSpeed);
    // },
    formatPrice(price) {
      return formatter.format(price);
    },
    // Never called JoseV.
    // changeActivekey(key) {
    //     this.addonSelected = key;
    // },
    getProductName(productId) {
      return this.productsAddons.find((prod) => prod.id == productId).name;
    },
    getAllProducts() {
      let self = this;
      db.collection(`products`)
        .where("business", "array-contains", this.selectedBusiness[".key"])
        .where("deleted", "==", true)
        .get()
        .then((querySnapshot) => {
          self.allProducts = [];
          querySnapshot.forEach(function (doc) {
            self.allProducts.push(doc.id);
          });
        })
        .catch((err) => console.log(err));
    },
  },
  // Never called JoseV.
  // firestore() {
  //     return {};
  // }
};
</script>

<style scoped>
.no-schedule {
  text-align: center;
}

.delete-schedule-container {
  display: flex;
  justify-content: flex-end;
}

.delete-schedule {
  margin: 0;
  cursor: pointer;
  padding-right: 5px;
  color: #f06f3d;
}

.add-schedule {
  display: flex;
  justify-content: center;
}

.item-addon {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.item-addon .price {
  color: rgb(240, 106, 37);
  font-weight: bold;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-left: 20px;
}

.item-addon .name {
  font-weight: bold;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.no-margin {
  margin: 0;
  padding: 0;
}

.reduce {
  border: 1px solid rgb(240, 106, 37);
  padding: 5px;
  border-radius: 10px;
  margin-right: 10px;
}

.increase {
  border: 1px solid rgb(240, 106, 37);
  padding: 5px;
  border-radius: 10px;
  margin-left: 10px;
}

.count {
  color: rgb(240, 106, 37);
}
</style>
