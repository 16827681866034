<template>
  <v-card>
    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>
    <v-overlay class="loading-center" :value="saving">
      <lottie
        :options="defaultOptions"
        :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>
    <v-card-title class="grid-close">
      <span class="headline">Agregar nuevo producto</span>
      <v-btn
        style="right: 10px; top: 10px"
        icon
        color="primary"
        fab
        small
        absolute
        @click="$emit('cancel')"
      >
        <v-icon dark>fa-times</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="px-4">
      <v-tabs v-model="tab" align-with-title class="mb-6">
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab v-for="item in tabItems" :key="item.value">
          {{ item.text }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item key="general">
          <v-row>
            <v-col cols="12" md="4">
              <div style="display: grid">
                <img
                  @click.prevent="showFileChooser"
                  :class="{
                    fit: existImage,
                    'image-product': true,
                    'mt-2': true,
                  }"
                  :src="
                    mainImage ||
                    localImages[0] ||
                    'https://firebasestorage.googleapis.com/v0/b/el-ocho-data/o/product-default.jpg?alt=media&token=2936fc1f-b1de-49f8-81dd-8ad132ef52df'
                  "
                />
                <div
                  v-if="localImages.length > 0"
                  class="mb-3"
                  style="text-align: center"
                >
                  <v-btn
                    small
                    color="red darken-3 mt-2 ml-2"
                    @click="deleteImage()"
                    class="white--text delete-image"
                  >
                    Eliminar
                  </v-btn>
                </div>
              </div>
              <v-row style="justify-content: center">
                <v-col
                  @click="viewImage(img)"
                  v-for="(img, index) of localImages"
                  :key="product.images.length + index"
                  cols="6"
                  sm="3"
                >
                  <v-img class="img-small" :src="img"></v-img>
                </v-col>

                <v-col cols="6" sm="3">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <div
                        v-on="on"
                        @click.prevent="showFileChooser"
                        class="add-image"
                      >
                        <i class="fas fa-plus"></i>
                      </div>
                    </template>
                    <span>Seleccionar nueva imágen</span>
                  </v-tooltip>
                </v-col>
              </v-row>

              <input
                class="upload"
                ref="input"
                type="file"
                name="image"
                accept="image/*"
                multiple
                @change="imageChanged"
              />
            </v-col>
            <v-col cols="12" md="8">
              <v-row>
                <v-col cols="12" md="4">
                  <p>SKU del producto</p>
                  <input
                    class="control-input control-input-number mt-8"
                    type="text"
                    placeholder="SKU"
                    v-model="product.sku"
                  />
                </v-col>

                <v-col cols="12" md="4">
                  <p>Precio del comercio</p>
                  <input
                    class="control-input control-input-number mt-8"
                    type="number"
                    @keypress="isNumber($event, product.businessPrice)"
                    v-model.number="product.businessPrice"
                  />
                </v-col>

                <v-col cols="12" md="4">
                  <p>Precio de final (con impuesto incluido)</p>
                  <input
                    class="control-input control-input-number"
                    type="number"
                    @keypress="isNumber($event, product.price)"
                    v-model.number="product.price"
                  />
                </v-col>
                <v-col cols="12" sm="8">
                  <p>Nombre del producto</p>
                  <input
                    class="control-input"
                    type="text"
                    placeholder="Ingrese el nombre"
                    v-model="product.name"
                  />
                </v-col>

                <v-col cols="12" sm="4">
                  <p>Precio de envío</p>
                  <input
                    class="control-input control-input-number"
                    type="number"
                    @keypress="isNumber($event, product.fixedDeliveryPrice)"
                    v-model.number="product.fixedDeliveryPrice"
                  />
                </v-col>

                <v-col cols="12">
                  <p>Descripción <small>(500 Max)</small></p>
                  <textarea
                    @keypress="maxLength($event, product.description, 500)"
                    placeholder="Descripción corta del producto"
                    v-model="product.description"
                    class="control-input"
                    rows="3"
                  ></textarea>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-switch
                v-model="product.isAvailable"
                label="Disponible en el app"
              ></v-switch>
            </v-col>
            <v-col cols="12" md="4">
              <v-switch
                v-model="product.adults"
                label="Prohibida la venta a menores"
              ></v-switch>
            </v-col>
            <v-col cols="12" md="4" v-if="roles.includes(user.type)">
              <v-switch
                v-model="product.isFreeDelivery"
                label="Aplica delivery gratis"
              ></v-switch>
            </v-col>

            <v-col cols="12" md="4">
              <v-switch
                label="Visible en el app"
                v-model="product.active"
              ></v-switch>
            </v-col>

            <v-col v-if="roles.includes(user.type)" cols="12" md="4">
              <v-switch
                label="Producto popular"
                v-model="product.isPopular"
              ></v-switch>
            </v-col>
            <v-col v-if="roles.includes(user.type)" cols="12" md="4">
              <v-switch
                label="Activar precio envío"
                v-model="product.isFixedDeliveryFee"
              ></v-switch>
            </v-col>
          </v-row>
        </v-tab-item>

        <v-tab-item key="sections">
          <v-row align="center">
            <v-col cols="12">
              <a-transfer
                v-if="isMobile"
                :listStyle="{
                  height: '300px!important',
                  width: '100%',
                }"
                :operationStyle="{
                  width: '100%',
                  textAlign: '-webkit-center',
                  transform: 'rotate(90deg)',
                }"
                :data-source="menuSections"
                :titles="['Todas las secciones', 'Secciones del producto']"
                :target-keys="product.menu"
                :selected-keys="selectedKeys"
                :show-search="true"
                @search="handleSearch"
                :render="(item) => item.title"
                :disabled="disabled"
                :filter-option="filterOption"
                @change="handleChange"
                @selectChange="handleSelectChange"
                @scroll="handleScroll"
                :locale="{
                  itemUnit: '',
                  itemsUnit: '',
                  notFoundContent: 'Lista vacía',
                  searchPlaceholder: 'Buscar sección',
                }"
              />
              <a-transfer
                v-else
                :listStyle="{
                  height: '500px!important',
                  width: '45%',
                }"
                :operationStyle="{
                  width: '8%',
                  textAlign: '-webkit-center',
                }"
                :data-source="menuSections"
                :titles="['Todas las secciones', 'Secciones del producto']"
                :target-keys="product.menu"
                :selected-keys="selectedKeys"
                :show-search="true"
                @search="handleSearch"
                :render="(item) => item.title"
                :disabled="disabled"
                :filter-option="filterOption"
                @change="handleChange"
                @selectChange="handleSelectChange"
                @scroll="handleScroll"
                :locale="{
                  itemUnit: '',
                  itemsUnit: '',
                  notFoundContent: 'Lista vacía',
                  searchPlaceholder: 'Buscar sección',
                }"
              />
            </v-col>
          </v-row>
        </v-tab-item>

        <v-tab-item key="details">
          <v-row>
            <v-col cols="12" md="6">
              <p>Unidades del producto que puede comprar un cliente</p>
              <v-select
                rounded
                outlined
                class="mt-3"
                placeholder="Elija una opción"
                v-model="product.userMax"
                :items="maxAllowedOptions"
              >
                <template v-slot:item="data">
                  <p v-if="data.item.value == 0">Todas las que desee</p>
                  <p v-else>{{ data.item.text }}</p>
                </template>
                <template v-slot:selection="data">
                  <p v-if="data.item.value == 0">Todas las que desee</p>
                  <p v-else>{{ data.item.text }}</p>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" md="6">
              <p>Seleccione tipo de entrega del producto</p>
              <v-select
                rounded
                outlined
                class="mt-2"
                item-text="text"
                item-value="value"
                placeholder="Elija una opción"
                v-model="product.deliveryType"
                :items="deliveryType"
              ></v-select>
            </v-col>
            <!-- <v-col cols="12" md="6">
              <p>Días de preparación del producto</p>
              <input
                class="control-input control-input-number"
                type="number"
                @keypress="isNumber($event, product.daysPreOrder)"
                placeholder="Días de preparación"
                v-model.number="product.daysPreOrder"
              />
            </v-col> -->
            <!-- <v-col cols="6">
              <p>Cantidad en Invenario</p>
              <input
                class="control-input control-input-number"
                type="number"
                :min="0"
                @keypress="isNumber($event, product.stock)"
                placeholder="Cantidad en invenario"
                v-model="product.stock"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-switch
                v-model="product.isStockRequired"
                label="El Producto se vende bajo inventario"
              ></v-switch>
            </v-col> -->
            <v-col cols="12" sm="6">
              <v-switch
                v-model="product.isScheduled"
                label="Calendarizable"
              ></v-switch>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions style="justify-content: flex-end">
      <v-btn class="px-4" color="primary" @click="newProduct()">
        Guardar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { db, fb } from "@/firebase";
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import { mapState } from "vuex";
import transfer from "ant-design-vue/lib/transfer";
import ledgerMixin from "@/mixins/ledgerMixin";

export default {
  name: "new-product",
  mixins: [lottieSettings, ledgerMixin],
  props: ["selectedSection"],
  components: {
    lottie: Lottie,
    "a-transfer": transfer,
  },
  data() {
    return {
      tab: null,
      isMobile: screen.width <= 800 ? true : false,
      selectedKeys: [],
      disabled: false,
      menuSections: [],
      product: {
        active: true,
        position: "",
        userMax: 0,
        addon: false,
        addonPrice: 0,
        addons: [],
        adults: false,
        isFreeDelivery: false,
        sku: "",
        business: [],
        calories: 0,
        deleted: false,
        description: "",
        disclaimer: "",
        discount: 0,
        expires: false,
        expiresOn: new Date(),
        createdAt: new Date(),
        createdBy: this.$store.state.user[".key"],
        featured: false,
        fractionable: false,
        height: 0,
        instructions: "",
        isAvailable: true,
        menu: [],
        minStock: 0,
        name: "",
        ochoActive: true,
        owner: "ocho",
        presentations: false,
        price: 0,
        businessPrice: 0,
        rating: 0,
        schedule: [],
        sizeUnits: "",
        sold: 0,
        tags: [""],
        takeOutCost: "",
        tax: 0.15,
        timeToProcess: 0,
        url: "",
        images: [],
        userLimit: false,
        userMax: 0,
        visible: true,
        visits: 0,
        volume: 0,
        deliveryType: "regularPickUp",
        isStockRequired: false,
        stockAmount: 0,
        stock: 0,
        isFixedDeliveryFee: false,
        fixedDeliveryPrice: 0,
      },
      saving: false,
      snackbar: false,
      imageFile: null,
      localImages: [],
      localImagesFiles: [],
      mainImage: null,
      imgPreview: null,
      snackbarText: "",
      maxAllowedOptions: [],
      deliveryType: [
        { text: "Solo para delivery", value: "regular" },
        {
          text: "Solo para pasarla recogiendo (no para delivery)",
          value: "pickUp",
        },
        {
          text: "Ambas opciones aplican (delivery y recoger)",
          value: "regularPickUp",
        },
      ],
      tabItems: [
        {
          text: "General",
          value: "general",
        },
        {
          text: "Secciones",
          value: "sections",
        },
        {
          text: "Detalles",
          value: "details",
        },
      ],
      roles: ["admin", "sudo", "AC", "EFCM"],
    };
  },
  computed: {
    ...mapState(["selectedBusiness", "user"]),
    existImage() {
      return this.localImages.length > 0 ? true : false;
    },
  },
  mounted() {
    // console.log(this.selectedSection);
    this.product.menu = [this.selectedSection];

    for (let index = 0; index < 100; index++) {
      this.maxAllowedOptions.push({ text: index, value: index });
    }

    db.collection(`businesses/${this.selectedBusiness[".key"]}/menu`)
      .get()
      .then((menuSubcollection) => {
        this.menuSections = [];

        menuSubcollection.forEach((subcollection, index) => {
          if (
            (subcollection.data().deleted == undefined ||
              subcollection.data().deleted == false) &&
            subcollection.data().name
          ) {
            this.menuSections.push({
              key: subcollection.id,
              title: subcollection.data().name,
            });
          }
        });
      });
  },
  methods: {
    maxLength(evt, field, length) {
      if (field.length >= length) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    deleteImage() {
      let position = null;

      this.localImages.map((item, i) => {
        if (item === this.mainImage) {
          position = i;
        }
      });

      this.localImages = this.localImages.filter(
        (image) => image != this.mainImage
      );

      const tempFiles = this.localImagesFiles;
      this.localImagesFiles = [];

      tempFiles.forEach((item, index) => {
        if (index != position) {
          this.localImagesFiles.push(item);
        }
      });

      this.mainImage = this.localImages[0] ? this.localImages[0] : "";
    },
    viewImage(image) {
      this.mainImage = image;
    },
    isNumber: function (evt, value) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;

      if (value && !Number.isInteger(value) && charCode == 46) {
        evt.preventDefault();
      }

      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    handleChange(nextTargetKeys, direction, moveKeys) {
      this.product.menu = nextTargetKeys;
    },
    handleSearch(dir, value) {
      return value.toUpperCase();
    },
    handleSelectChange(sourceSelectedKeys, targetSelectedKeys) {
      this.selectedKeys = [...sourceSelectedKeys, ...targetSelectedKeys];
    },
    filterOption(inputValue, option) {
      return option.title.toUpperCase().indexOf(inputValue.toUpperCase()) > -1;
    },
    handleScroll(direction, e) {},
    handleDisable(disabled) {
      this.disabled = disabled;
    },
    imageChanged(e) {
      if (e.target.files.length > 3) {
        this.snackbarText = "Solo se permiten 3 imágenes por producto";
        this.snackbar = true;
        return;
      }

      if (!e.target.files[0]) {
        return;
      }

      this.localImagesFiles.push(...e.target.files);

      let ref = this;
      this.imageFile = e.target.files[0];

      if (this.imageFile && this.imageFile.type.indexOf("image/") === -1) {
        console.log("tipo de archivo no permitido");
        this.imageFile = null;
      }

      for (let i = 0; i < e.target.files.length; i++) {
        const file = e.target.files[i];

        var reader = new FileReader();

        reader.onload = (e) => {
          ref.localImages.push(e.target.result);
        };

        if (file) {
          reader.readAsDataURL(file);
        }
      }

      // e.target.files.forEach((file) => {
      //   var reader = new FileReader();
      //   reader.onload = function (e) {
      //     ref.localImages.push(e.target.result);
      //   };

      //   if (file) reader.readAsDataURL(file);
      // });
    },
    createdSuccess: function () {
      this.saving = false;
      this.imageFile = null;
      this.product.name = "";
      this.product.description = "";
      this.product.price = 0;
      this.product.menu = [];
      this.product.adults = false;
      this.product.isFreeDelivery = false;
      this.product.featured = false;
      this.product.sku = "";
      this.$emit("success");
    },
    newProduct() {
      this.product.createdAt = new Date();

      this.product.price = Number.parseFloat(this.product.price);
      if (Number.isNaN(this.product.price)) this.product.price = 0;

      if (this.product.menu.length == 0) {
        this.snackbarText = "Debe seleccionar una sección de producto";
        this.snackbar = true;
        return;
      }

      if (!this.product.deliveryType) {
        this.snackbarText = "Debe seleccionar un tipo de entrega";
        this.snackbar = true;
        return;
      }

      this.product.business = [this.selectedBusiness[".key"]];
      this.product.userLimit = this.product.userMax == 0 ? false : true;

      if (this.product.name) {
        this.saving = true;
        if (this.product.isFreeDelivery) {
          db.collection("businesses")
            .doc(this.selectedBusiness[".key"])
            .update({
              freeShippingProductsIsAvailable: true,
            });
        }
        db.collection("products")
          .add(this.product)
          .then((ref) => {
            if (this.localImagesFiles.length > 0) {
              this.localImagesFiles.forEach((image) => {
                fb.app()
                  .storage(`gs://${process.env.VUE_APP_PROJECT_ID}-products`)
                  .ref()
                  .child(`${ref.id}/${image.name}`)
                  .put(image)
                  .then(() => {
                    this.createdSuccess();
                  })
                  .catch((err) => {
                    console.log(err);
                    this.snackbarText =
                      "Ocurrió un error al subir la imágen, actualice desde la página de productos";
                    this.snackbar = true;
                    this.saving = false;
                  });
              });
            } else {
              this.createdSuccess();
            }
            this.createLedger({
              source_id: ref.id,
              source_type: "products",
              currentValue: this.product,
              prevValue: {},
            });
          })
          .catch((err) => {
            console.log(err);
            this.snackbarText = "Ocurrió un error, inténtelo nuevamente.";
            this.snackbar = true;
            this.saving = false;
          });
      } else {
        this.snackbarText = "Debe ingresar un nombre al producto";
        this.snackbar = true;
      }
    },
    showFileChooser() {
      if (this.product.images.length + this.localImages.length < 3)
        this.$refs.input.click();
      else {
        this.snackbarText = "Solo se permiten 3 imágenes por producto";
        this.snackbar = true;
      }
    },
  },
};
</script>

<style scoped>
.btn-modal-add-tags {
  justify-content: center;
}
.add-tag-ico {
  font-size: 30px !important;
  padding: 10px;
}
.close-modal {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.grid-close {
  display: grid;
  grid-template-columns: auto auto;
}
.fa-times {
  color: gray;
}
.image-product {
  max-width: 100%;
  min-width: 100%;
  max-height: 230px;
  min-height: 230px;
  cursor: pointer;
  border: 1px solid rgba(128, 128, 128, 0.062);
  border-radius: 10px;
  object-fit: cover;
}
.fit {
  object-fit: contain;
}
p {
  padding: 0;
  margin: 0;
  color: rgba(0, 0, 0, 0.836);
  font-size: 16px;
}
.theme--light.v-list {
  border: 1px solid rgba(0, 0, 0, 0.26) !important;
  border-radius: 10px !important;
  background-color: white !important;
  padding: 10px !important;
  margin-top: 15px !important;
  min-height: 500px !important;
}
.save-btn {
  margin-top: 20px;
}
.actions {
  position: fixed;
  bottom: 0;
  width: 90%;
  bottom: 20px;
  /* padding-right: 20px; */
  background-color: white;
}
.fixed-position {
  z-index: 100;
}
.upload {
  display: none;
}
.img-small {
  border: 1px solid rgba(0, 0, 0, 0.219);
  border-radius: 5px;
  cursor: pointer;
  max-height: 70px;
  min-height: 70px;
  object-fit: cover;
}
.add-image {
  height: 70px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-image i {
  font-size: 20px;
  color: #f06a26;
}
.add-image:hover {
  border-radius: 10px;
  background-color: #f069263b;
  cursor: pointer;
}
</style>
