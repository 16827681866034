<template>
  <div>
    <v-snackbar absolute top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>

    <v-overlay class="loading-center" :value="saving">
      <lottie
        :options="defaultOptions"
   :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>

    <v-card>
      <v-card-title class="grid-close">
        <span class="headline">Ordenar productos</span>
        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>

        <v-autocomplete
          v-model="sectionSelected"
          :items="sections"
          prepend-icon="fa-filter"
          label="Filtrar por categoría"
          item-text="name"
          item-value=".key"
          aria-autocomplete="false"
          class="mt-10"
          autocomplete="off"
          hide-details
          filled
          rounded
          :allow-overflow="false"
          clearable
        >
        </v-autocomplete>
      </v-card-title>
      <v-card-text class="height-modal">
        <div
          v-if="localProducts.length == 0"
          class="mt-10"
          style="text-align: center"
        >
          <span>categoría vacía 😔</span>
        </div>

        <draggable
          v-bind="dragOptions"
          @change="onChange"
          ghost-class="ghost"
          v-model="localProducts"
          @start="drag = true"
          @end="drag = false"
        >
          <transition-group type="transition" name="flip-list">
            <v-list-item
              style="cursor: move"
              class="list-group-item"
              v-for="item in localProducts"
              :key="item.id"
            >
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>

              <v-list-item-icon>
                <v-icon>fas fa-bars</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </transition-group>
        </draggable>
      </v-card-text>

      <v-card-actions>
        <v-btn
          :disabled="!changed"
          color="primary"
          :loading="saving"
          block
          class="mb-5"
          @click="updatePosition()"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";
import { Parser } from "json2csv";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import { mapActions, mapState } from "vuex";
import draggable from "vuedraggable";
import ledgerMixin from "@/mixins/ledgerMixin";

export default {
  name: "Sort",
  props: ["products"],
  mixins: [lottieSettings, ledgerMixin],
  components: {
    lottie: Lottie,
    draggable,
  },
  data() {
    return {
      snackbar: false,
      snackbarText: "",
      saving: false,
      loading: true,
      sections: [],
      sectionSelected: null,
      localProducts: [],
      changed: false,
      drag: false,
    };
  },
  computed: {
    ...mapState(["selectedBusiness"]),

    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    sortedArrayProductsByPosition: function () {
      function compare(a, b) {
        if (a.position && b.position && a.position < b.position) return -1;
        if (a.position && b.position && a.position > b.position) return 1;
        return 0;
      }

      return this.localProducts.sort(compare);
    },
  },
  watch: {
    sectionSelected(data) {
      this.localProducts = this.products.filter((item) =>
        item.menu.includes(this.sectionSelected)
      );
      this.localProducts = Object.assign(
        [],
        this.sortedArrayProductsByPosition
      );

      this.changed = false;
    },
  },
  async mounted() {
    this.$binding(
      "sections",
      db.collection(`businesses/${this.selectedBusiness[".key"]}/menu`)
    ).then((sections) => {
      this.sections = this.sections.filter((item) => !item.deleted);
      this.sections = this.sortedSections()
      if (sections.length > 0) {
        this.sectionSelected = sections[0][".key"];
      }
    });
  },
  methods: {
    sortedSections() {
      function compare(a, b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }

      return this.sections.sort(compare);
    },
    updatePosition() {
      this.saving = true;

      this.localProducts.forEach((prod, index) => {
        let position = index + 1;
        db.collection("products").doc(prod.id).update({
          position: position,
        });

        let prevProduct = this.products.filter((_product) => {
          return _product.id === prod.id;
        })[0];

        this.createLedger({
          source_id: prod.id,
          source_type: "products",
          currentValue: {
            postion: position,
          },
          prevValue: {
            postion: prevProduct["position"],
          },
        });

        if (this.localProducts.length == index + 1) {
          this.snackbarText =
            "Posiciones de los productos actualizadas correctamente.";
          this.snackbar = true;
          this.changed = false;
          this.saving = false;
        }
      });
    },
    onChange() {
      this.changed = true;
    },
  },
};
</script>

<style scoped>
.height-modal {
  height: 50vh;
  max-height: 50vh;
  min-height: 50vh;
  overflow-y: scroll;
}
.list-group-item {
  border-bottom: 1px solid rgba(128, 128, 128, 0.425);
}
.ghost {
  opacity: 0.5;
  background: #e0b19d;
}
.flip-list-move {
  transition: transform 0.5s;
}
</style>
